.theme_dark {
    --fp-gf-bg: #222222;
    
    --fp-tile-bg: rgb(48, 47, 47);
    --fp-tile-paused-bg: rgb(65, 64, 64);
    --fp-tile-hover-bg: rgb(65, 64, 64);

    --fp-modal-bg: #8c8c8ccc;
    --fp-modal-text: rgb(43, 43, 43);
    --fp-modal-btn-text: rgb(199, 198, 198);
    --fp-modal-caption: #2e2e2e;
    --fp-modal-span: #fadf12;

    --fp-tile-dev-bg: rgb(74, 74, 74);
}

.theme_light {
    --fp-gf-bg: #e5e5e5;
    
    --fp-tile-bg: rgb(213, 213, 213);
    --fp-tile-paused-bg: rgb(209, 209, 209);
    --fp-tile-hover-bg: rgb(209, 209, 209);

    --fp-modal-bg: #6e6e6ecc;
    --fp-modal-text: #e7e7e7;
    --fp-modal-btn-text: rgb(96, 92, 92);
    --fp-modal-caption: #e7e7e7;
    --fp-modal-span: #fadf12;

    --fp-tile-dev-bg: rgb(165, 165, 165);
}

.gamefield {
    margin: auto;
    display: grid;
    gap: 10px;
    padding: 20px;
    background-color: var(--fp-gf-bg);
    border-radius: 15px;
    z-index: 10;
    transition: all 0.5s ease;
}

/* gfd4x4 */
.gfl1 {
    grid-template-columns: repeat(4, 1fr);
    max-width: 60vw;
}

/* gf6x4 */
.gfl2 {
    grid-template-columns: repeat(6, 1fr);
    max-width: 65vw;
}

/* gf8x8 */
.gfl3 {
    grid-template-columns: repeat(8, 2fr);
    max-width: 70vw;
}


.dragging {
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    user-select: none;
}

.tile {
    position: relative;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    background-color: var(--fp-tile-bg);
    padding-top: 59%;
    cursor: pointer;
}

.tile__paused {
    background-color: var(--fp-tile-paused-bg);
    transition: 0.2s ease-in-out;
    cursor: auto;
}

.tile:hover {
    background-color: var(--fp-tile-hover-bg);
    transition: 0.2s ease-in-out;
}

.tile img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}


.modal {
    position: fixed;
    /* Используем fixed для позиционирования относительно вьюпорта */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vh 5vw;
    background-color: var(--fp-modal-bg);
    color: var(--fp-modal-text);
    border-radius: 20px;
    z-index: 11;
}

.modal__caption {
    color: var(--fp-modal-caption);
    font-size: larger;
    font-weight: bold;
    margin-bottom: 20px;
}

.modal p {
    padding: 2.5px;
    margin: 0;
}

.modal span {
    color: var(--fp-modal-span);
    font-weight: bolder;
}

.modal__buttons {
    display: flex;
    padding-top: 10px;
    gap: 10px;
}

.modal_button {
    font-weight: 500;
    color: var(--fp-modal-btn-text);
}

.modal__activate {
    opacity: 0.2;
    transition: opacity 250ms ease-in-out;
}

.pair {
    background-color: var(--fp-tile-dev-bg);
}

.img__container {
    width: 100%;
    height: 100%;
}

.game_start {
    margin: 20px;
    font-weight: 900;
    font-size: 0.8vw;
    padding: 1vw 2vw;
}

.center {
    align-self: center;
    position: relative;
}

.panel_control {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}