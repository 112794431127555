.space {
    display: inline-block;
    width: 0.3em;
    opacity: 0.5;
    /* Размер блока, чтобы пробелы не смещали текст */
}

/* Остальные стили */
.theme_light {
    --text_entered: #3a3a3a;
    --text-displayed: #999999;
}

.theme_dark {
    --text_entered: #959595;
    --text-displayed: #525252;
}

.container {
    display: flex;
    position: relative;
    align-items: start;
    color: var(--text);
    width: 80%;
    margin: auto;
}

.text_content {
    flex: 4;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 100%;
}

.selector {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.selector>p {
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    padding: 0 0 30px 0;
}

.selector_i {
    padding: 10px;
    width: 150px;
    background-color: var(--block-background);
    border-radius: 10px;
    border: 1px solid var(--btn-background);
    text-align: center;
    color: var(--block-text);
    font-weight: 400;
    cursor: pointer;
}

.selector_i:hover {
    transform: scaleX(1.05);
    transition: all 0.1s ease-in-out;
}

.text {
    margin-bottom: 20px;
    width: 100%;
    max-width: 700px;
    position: relative;
    font-family: 'Arial', sans-serif;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    color: var(--text-displayed);
}

.text span {
    line-height: 1.5;
    font-size: 1.2em;
}

.correct {
    color: var(--text_entered);
}

.incorrect {
    color: rgb(250, 94, 94);
}

.input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    outline: none;
    cursor: default;
}

.sub_container {
    position: relative;
}

.inputs {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    outline: none;
    border: none;
}

.button {
    padding: 10px 20px;
    font-size: 0.8em;
    cursor: pointer;
}

.results {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
}