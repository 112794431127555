.container {
    margin: 0 20px;
    font-size: 14pt;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    text-transform: lowercase;
}

.synonym {
    flex: 1;
    text-align: left;
    font-size: 1.3em;
}

.container>nav {
    flex: 2;
    text-align: center;
}

.container>.buttons {
    flex: 1;
    text-align: right;
    display: flex;
    gap: 20px;
    justify-content: end;
    align-items: center;
}

.container>.buttons>button {
    font-size: 1em;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: var(--text-color);
    transition: transform 0.3s ease;
}

.container>.buttons>button:hover {
    transform: scale(1.1);
}

.container li {
    list-style-type: none;
    display: inline-block;
    padding: 0 10px;
}

a {
    color: var(--text-color);
    text-decoration: none;
    transition: color 0.25s;
}

a:hover {
    color: var(--text-color-softer);
}

.container>span {
    color: black;
}