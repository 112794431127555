.theme_light {
    --text-inactive: #A4A4A4;
    --text-default: #292929;
}
.theme_dark {
    --text-inactive: #dadada;
    --text-default: #ffffff;
}

.input_container, .input, .add_input, span {
    color: var(--text-inactive);
    opacity: 0.7;
    outline: none;
    border: none;
    background: transparent;
    font-size: 12pt;
    font-weight: 400;
    width: 80%;
}
.active,
.active input,
.active .input_container:hover,
.active span  {
    color: var(--text-default) !important;
    opacity: 1 !important;
}

.input_container:hover,
.input_container:hover input,
.input_container:hover .icon {
    color: var(--text-default);
    opacity: 0.75;
}

.add_input {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    margin-left: 20px;
}

.icon {
    cursor: pointer;
    margin-right: 5px;
    opacity: 0;
}

