@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

.montserrat__semibold {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: 0;
  height: 100vh;
  box-sizing: border-box;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.Montserrat600 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.light-theme {
  --background-color: #f1f1f1;
  --background-color-sub: #f7f6f6;

  --border: #e8e7e7;
  --text: #5d5d5d;

  --text-color: #000;
  --text-color-softer: #b8b8b8;

  --block-background: #e6e6e6;
  --block-text: black;

  --btn-background: #dedcdc;
  --btn-text: #a2a2a2;
  --btn-border: #d3d2d2;

  --btn-light-background: #f8f8f8;
  --btn-light-text: #8b8b8b;
  --btn-light-border: #d3d2d2;

  --panel-light: #fbfbfb;
  --panel-light-border: #dddcdc;
  --panel-light-soft: #f2f2f2;

  --input-light-inner: #f5f5f5;
  --input-light-border: #dddddd;
  --input-light-text: #747474;
  
  transition: var(--change-theme-fast);
}

.dark-theme {
  --background-color: #141414;
  --background-color-sub: #232323;;

  --border: #222222;
  --text: #aeaeae;

  --text-color: #f7f7f7;
  --text-color-softer: #b8b8b8;

  --block-background: #353434;
  --block-text: rgb(139, 139, 139);
  
  --btn-background: #424242;
  --btn-text: #b2b2b2;
  --btn-border: #474747;

  --panel-light: #0f0f0f; 
  --panel-light-soft: #171717;
  
  --input-light-inner: #191919;
  --input-light-border: #191919;
  --input-light-text: #c0c0c0;
  
  transition: var(--change-theme-fast);
}

a {
  color: var(--text);
}


button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: var(--btn-text);
  border: 1px solid var(--btn-border);
  background-color: var(--btn-background);
  border-radius: 10px;
  padding: 10px 20px;
}


.page {
  padding: 10px 20px;
  height: calc(100vh - 80px);
}

.centered_flex {
  display: flex;
  justify-self: center;
  align-items: center;
}

:root {
  --change-theme-fast: background-color 0.25s ease, color 0.25s ease;
}