.sidebar {
    color: var(--text-color);
    background-color: var(--background-color-sub);
    border: .5px solid var(--border);
    width: 200px;
    height: 100%;
}

.category { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: .5px solid;
    border-bottom-color: #464646;
}

.category:last-child {
    border-bottom: none;
}

.cat_label, .cat_label span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
}

.cat_label svg {
    font-size: 0.9em;
}


.cat_content { 
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.cat_item {
    width: 100%;
    height: 30px;
    background-color: var(--block-background);

    text-align: center;
}
