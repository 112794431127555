.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.theme_light {
    --card-background: rgb(238, 237, 237);
}

.theme {
    --card-background: var(--background-color-sub);
}


.card {
    background-color: var(--card-background);
    border: 0.5px solid var(--border);
    color: var(--text);
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 300px;
    justify-content: space-between;
    border-radius: 20px;
    padding: 20px;
}

.card {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    -webkit-user-select: none;
    user-select: none;
}

.card.flipped {
    transform: rotateY(180deg);
}


p {
    padding: 0;
    margin: 0;
}

.ls {
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
}

.lsb {
    cursor: pointer;
}

.jc {
    justify-content: space-between;
}

.la {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.la .word {
    font-size: 2em;
    font-weight: 700;
}

.word {
    text-transform: uppercase;
}

.pic {
    width: 75px;
    height: 75px;
}
