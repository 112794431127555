.wrap {
    position: relative;
    width: 100%;
    background-color: var(--input-light-inner);
    border: .5px solid var(--input-light-border);
    border-radius: 10px;
    cursor: text;

}

.field {
    width: 100%;
    height: 50px;
    border: 0;
    outline: none;
    font-size: 12pt;
    line-height: 1.6;
    background-color: transparent;
    box-sizing: border-box;
    position: relative;
    z-index: 2;

    color: var(--input-light-text);
}

.field:focus+.label,
.wrap_changed .label {
    top: 4px;
    left: 15px;
    font-size: 8pt;
    transform: translateY(0);
}

.label {
    position: absolute;
    top: 50%;
    left: 14px;
    font-size: 15px;
    line-height: 1.6;
    color: var(--input-light-text);
    transition: all .3s;
    transform: translateY(-50%);
    cursor: text;
    z-index: 1;
}

.field:focus,
.wrap_changed .field {
    padding: 16px 16px 0px 16px;
}

.cb_wrap {
    margin-bottom: 20px;
    display: flex;
}

.cb_label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.cb_label input {
    margin-right: 10px;
}