.page {
    display: flex;
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    padding: 10px 20px;
    gap: 10px;
    /* max-height: 100%; */
    max-height: calc(100vh - 60px);
}

.container {
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    
    font-size: 11pt;
    font-weight: 500;
    width: 100%;
    height: 100%;
    /* border: 1px solid red;
    border-style: dashed; */
}

.inFlex3 {
    width: auto;
    height: auto;
    flex: 3;
}

.flex_centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sub_header h1, .sub_header h2 {
    margin: 0;
}

.sub_header h1 {
    font-weight: 700;
    font-size: 2.4em;
    display: flex;
    align-items: center;
    gap: 10px;
    /* justify-content: baseline; */
}

.sub_header .timer {
    font-size: calc(1em / 2.4 + 0.25em/2.4);
    font-weight: 400;
    /* height: 100%; */
    padding: 2.5px 15px;
    color: var(--btn-text);
    background-color: var(--btn-background);
    border: 1px solid var(--btn-border);
    border-radius: 20px;
}

.sub_header h2 {
    font-weight: 400;
    font-size: 1.5em;
}

.content {
    margin-top: 60px;
}

.pad_group {
    padding-left: 30px;
}

.sub_footer {
    position: absolute;
    bottom: 20px;
    display: flex;
    gap: 10px;
}

.sub_footer > p {
    padding: 0;
    cursor: pointer;
}

.theme_light {
    --car_i_background: #e8e8e8;
    --car_i_border: #dcdcdc;
    --car-i-text1: #5D5D5D;
}
    
.theme_dark {
    --car_i_background: #212121;
    --car_i_border: #242424;
    --car-i-text1: #a3a3a3;
}

.carousel {
    padding: 40px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.carousel a {
    text-decoration: none;
    font-weight: 400;
    padding: 0;
    color: var(--car-i-text1);
}

.carousel_item {
    box-sizing: border-box;
    background-color: var(--car_i_background);
    border: 1px solid var(--car_i_border);
    width: 100%;
    border-radius: 10px;
    height: 150px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px;

    text-transform: lowercase;
}

.carousel_item:hover {
    transform: scale(1.02);
}

.carousel_desc {
    text-align: end;
    font-size: 1em;
    font-weight: 350;
}

.carousel_item_name {
    font-weight: 400;
    font-size: 1.2em;
    padding: 10px;
    padding: 0;
    margin: 0;
}