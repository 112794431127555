.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
}

/* we should get two parts: first child: 1.5 width, second child: 0.5 width */
.grid {
    width: 100%;
    display: grid;
    padding: 30px;
    gap: 30px;
    grid-template-columns: 1fr 0.75fr;
}

.auth_form {
    width: 800px;
    height: 450px;
    background-color: var(--panel-light);
    border-radius: 20px;
    display: flex;
    border: .5px solid var(--panel-light-border);   
}

.form_minimized {
    width: 550px;
    height: 400px;
    justify-content: center;
}

.auth_form form {
    display: flex;
    flex-direction: column;
    gap: 7.5px;
}

.inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7.5px;
}

.inputs_max {
    align-self: center;
    width: 70%;
}

.btn_next {
    background-color: var(--input-light-inner);
    color: var(--input-light-text);
    border: .5px solid var(--input-light-border);
    border-radius: 10px;
    font-weight: 400;
    height: 40px;
}

.form_section_name { 
    width: 100%;
    font-weight: 600;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 10px;
}

.banner {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: var(--panel-light-soft);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.banner h2 {
    font-size: 1em;
    font-weight: 500;
}

.banner p, .banner a {
    font-size: 0.95em;
    /* font-weight: 300; */
}


.banner p, .banner h2, .banner h1 { 
    padding: 0;
    margin: 0;
    text-align: center;
    /* font-weight: 200; */
}

.banner span {
    height: 30px;
}

.banner div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error_message {
    font-size: 0.9em;
    margin: 0 auto;
}