.notification {
    padding: 20px 20px;
    width: 400px;
    /* height: 80px; */
    background-color: var(--panel-light);
    border-radius: 20px;
    position: absolute;
    z-index: 999;

    opacity: 0;
    transition: opacity .5s;

    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
}

.notification_active {
    opacity: 1;
    /* transition: opacity 2s; */
    /* pointer-events: auto; */
    /* transform: scaleY(100); */
}

.notification_content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.notification_icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification_text {
    color: var(--text-neutral);
}

.notification_text_title {
    color: var(--text-neutral);
}

.notification_text_body {
    color: var(--text-neutral);
}